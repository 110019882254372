import React from 'react';
import {
  AppCheckProvider,
  AuthProvider,
  FirebaseAppProvider,
  useFirebaseApp,
} from 'reactfire';
import { getAuth } from 'firebase/auth';
import ForceLoggedInWrapper from './components/auth/ForceLoggedInWrapper';
import Main from './components/Main';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: 'AIzaSyAkaJ3FamqYW0Q1ldz9Dx0cyrFCmF9SFpo',
  authDomain: 'campamentos-575.firebaseapp.com',
  databaseURL:
    'https://campamentos-575-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'campamentos-575',
  storageBucket: 'campamentos-575.appspot.com',
  messagingSenderId: '305976682334',
  appId: '1:305976682334:web:583c456a85b36bd32651cf',
};

const APP_CHECK_TOKEN = '6Lf9HmMoAAAAANUNB1CP9VdNWY6_O68L4Z6d-wks';

const FirebaseSdksProvider = (props: React.PropsWithChildren) => {
  const app = useFirebaseApp();
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });
  const auth = getAuth(app);

  return (
    <AppCheckProvider sdk={appCheck}>
      <AuthProvider sdk={auth}>{props.children}</AuthProvider>
    </AppCheckProvider>
  );
};

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseSdksProvider>
        <ForceLoggedInWrapper>
          <Main />
        </ForceLoggedInWrapper>
      </FirebaseSdksProvider>
    </FirebaseAppProvider>
  );
}

export default App;
