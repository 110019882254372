import './Header.css';
import { ReactComponent as Logo } from '../../assets/logo-sda.svg';
import LogoutButton from '../auth/LogoutButton';

const Header = () => {
  return (
    <>
      <header>
        <Logo />
        <LogoutButton />
      </header>
    </>
  );
};

export default Header;
