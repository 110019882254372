import Header from './navigation/Header';
import Map from './map/Map';
import { CampSite } from './map/MapMarker';
import { useState } from 'react';
import { MapTerrain } from './map/Terrain';

const sitios = [
  {
    coordinates: [36.527058, -5.58601, 1],
    name: 'La Sauceda',
  },
  {
    coordinates: [37.907337, -2.935241, 1],
    name: 'Puente de las Herrerías',
  },
  {
    coordinates: [39.649007, -8.832789, 1],
    name: 'Quinta do Escuteiro',
  },
];

const Main = () => {
  const [terrain, setTerrain] = useState<MapTerrain>(
    MapTerrain.STADIA_OUTDOORS,
  );
  const updateTerrain = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setTerrain(e.target.value as MapTerrain);

  return (
    <>
      <Header />
      <Map sites={sitios as Array<CampSite>} terrain={terrain} />
      <select onChange={updateTerrain}>
        <option value={MapTerrain.STADIA_OUTDOORS}>Político</option>
        <option value={MapTerrain.ESRI_WORLD_IMAGERY}>Satélite</option>
        <option value={MapTerrain.OPEN_TOPO_MAP}>Topográfico</option>
      </select>
    </>
  );
};

export default Main;
