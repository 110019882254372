import { useSigninCheck } from 'reactfire';
import Loader from '../loading/Loader';
import LoginPage from './LoginPage';

const STATUS_LOADING = 'loading';

const ForceLoggedInWrapper = (props: React.PropsWithChildren) => {
  const { status, data: signInCheckResult } = useSigninCheck();

  if (status === STATUS_LOADING) {
    return <Loader />;
  }

  if (signInCheckResult.signedIn === true) {
    return <>{props.children}</>;
  }

  return <LoginPage />;
};

export default ForceLoggedInWrapper;
