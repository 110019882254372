import { Marker, Tooltip } from 'react-leaflet';

export type CampSite = {
  coordinates: [number, number, number?];
  name: string;
};

const MapMarker = ({ coordinates, name }: CampSite) => {
  return (
    <Marker
      position={coordinates}
      eventHandlers={{
        click: () => alert(name),
      }}>
      <Tooltip>{name}</Tooltip>
    </Marker>
  );
};

export default MapMarker;
