import { TileLayer, TileLayerProps } from 'react-leaflet';

export enum MapTerrain {
  STADIA_OUTDOORS = 'STADIA_OUTDOORS',
  ESRI_WORLD_IMAGERY = 'ESRI_WORLD_IMAGERY',
  OPEN_TOPO_MAP = 'OPEN_TOPO_MAP',
}

const getBaseTileLayerDetails = (terrain: MapTerrain): TileLayerProps => {
  switch (terrain) {
    case MapTerrain.STADIA_OUTDOORS:
      return {
        attribution:
          '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
      };
    case MapTerrain.ESRI_WORLD_IMAGERY:
      return {
        attribution:
          'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      };
    case MapTerrain.OPEN_TOPO_MAP:
      return {
        attribution:
          'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
      };
  }
};

type TerrainProps = {
  terrain: MapTerrain;
};

const Terrain = ({ terrain }: TerrainProps) => {
  const tileLayerDetails = getBaseTileLayerDetails(terrain);
  return <TileLayer {...tileLayerDetails} />;
};

export default Terrain;
