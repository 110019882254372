import { signOut } from 'firebase/auth';
import { useAuth } from 'reactfire';

const LogoutButton = () => {
  const auth = useAuth();
  const logout = () => signOut(auth).catch(console.warn);

  return <button onClick={logout}>Cerrar sesión</button>;
};

export default LogoutButton;
