import { MapContainer } from 'react-leaflet';
import './Map.css';
import MapMarker, { CampSite } from './MapMarker';
import Terrain, { MapTerrain } from './Terrain';

type MapProps = {
  sites: Array<CampSite>;
  terrain: MapTerrain;
};

const mean = (elements: Array<number>): number =>
  elements.reduce((a, b) => a + b) / elements.length;

const Map = ({ sites, terrain }: MapProps) => {
  const latitudes = sites.map((site) => site.coordinates[0]);
  const longitudes = sites.map((site) => site.coordinates[1]);

  const centerX = mean(latitudes);
  const centerY = mean(longitudes);

  return (
    <div className="map-container">
      <MapContainer
        center={[centerX, centerY]}
        bounds={latitudes.map((lat, i) => [lat, longitudes[i]])}
        boundsOptions={{
          padding: [50, 50],
        }}
        scrollWheelZoom>
        <Terrain terrain={terrain} />
        {sites.map((site) => (
          <MapMarker {...site} />
        ))}
      </MapContainer>
    </div>
  );
};

export default Map;
