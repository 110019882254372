import { useState } from 'react';
import { useAuth } from 'reactfire';
import { signInWithEmailAndPassword } from 'firebase/auth';
import './LoginPage.css';
import { FirebaseError } from 'firebase/app';
import { ReactComponent as Logo } from '../../assets/logo-sda.svg';

type LoginButtonProps = {
  email: string;
  password: string;
  onError: (error: FirebaseError) => void;
};

const LoginButton = ({ email, password, onError }: LoginButtonProps) => {
  const auth = useAuth();

  const doSignIn = () =>
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => console.log(userCredential))
      .catch((error) => {
        if (error instanceof FirebaseError) {
          onError(error);
        } else {
          console.warn(error);
        }
      });

  return (
    <button className="login-button" onClick={doSignIn}>
      Acceder
    </button>
  );
};

type ResetPasswordButtonProps = {
  email: string;
};

const ResetPasswordButton = ({ email }: ResetPasswordButtonProps) => {
  return (
    <button className="login-button-secondary" onClick={console.log}>
      Recuperar contraseña
    </button>
  );
};

const LoginPage = () => {
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError('');
    setPasswordError('');
  };

  const [password, setPassword] = useState<string>('');
  const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setPassword(e.target.value);
    setPasswordError('');
  };

  const onLoginError = (error: FirebaseError) => {
    if (error.code === 'auth/invalid-email') {
      setEmailError('La cuenta de correo proporcionada no es válida');
    } else if (error.code === 'auth/user-disabled') {
      setEmailError('Usuario desactivado');
    } else if (error.code === 'auth/invalid-login-credentials') {
      setPasswordError('Contraseña incorrecta');
    }
  };

  return (
    <div className="login-wrapper">
      <Logo className="login-logo" />
      <div className="login-container">
        <div className="login-field">
          <label htmlFor="email">Usuario:</label>
          <input
            className={emailError ? 'login-field-failed' : ''}
            id="email"
            type="email"
            value={email}
            onChange={updateEmail}
          />
          {emailError ? <span>{emailError}</span> : null}
        </div>
        <div className="login-field">
          <label htmlFor="password">Contraseña:</label>
          <input
            className={passwordError ? 'login-field-failed' : ''}
            type="password"
            value={password}
            onChange={updatePassword}
          />
          {passwordError ? <span>{passwordError}</span> : null}
        </div>
        <div className="login-buttons">
          <LoginButton
            email={email}
            password={password}
            onError={onLoginError}
          />
          <ResetPasswordButton email={email} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
